import React, { useState, useMemo } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { Box, Button } from 'rebass';
import { Input, Label, Textarea } from '@rebass/forms';

import helpers from 'utils/helpers';
import studies_service from 'services/studies';
import people_service from 'services/people';
import Portal from 'components/Portal';
import NiceModal from 'components/NiceModal';
import NiceSelect from 'components/NiceSelect';
import { ActionButton } from 'components/Button/ActionButton';
import CreatePanel from 'components/Participant/CreatePanel';
import PanelSelect from 'components/PanelSelect';
import services from 'services/services';
import QualtricsSurveySelect from 'components/Qualtrics/QualtricsSurveySelect';
import { SCREENER_TYPE } from 'utils/constants';
import useQuery from 'hooks/useQuery';

/**
 * Display a New Screener button.
 *
 * @param {Object} props
 * @param {number} props.studyId study ID
 * @param {Object} props.auth Auth object
 **/
function NewScreener({ studyId, auth }) {
    const { addToast } = useToasts();
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [showModalCreatePanel, setShowModalCreatePanel] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [panelIdSelected, setPanelIdSelected] = useState(null);
    const [screenerType, setScreenerType] = useState(SCREENER_TYPE.PANELFOX);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [copyScreenerId, setCopyScreenerId] = useState(null);
    const [qualtricsSurveyId, setQualtricsSurveyId] = useState(null);
    const { data: panels, refetch: refetchPanels } = useQuery({
        queryFn: () => people_service.getPanels(),
        onSuccess: data => {
            if (data.length && !panelIdSelected) {
                setPanelIdSelected(data[0].id);
            }
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const { data: allAccountScreeners } = useQuery({
        queryFn: () => studies_service.getAllAccountScreeners(),
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    const screenerTypes = [{ id: SCREENER_TYPE.PANELFOX, title: 'Panelfox Screener', icon: '' }];
    if (helpers.hasQualtricsIntegration(auth)) {
        screenerTypes.push({
            id: SCREENER_TYPE.QUALTRICS,
            title: 'Qualtrics Survey',
            icon: <img src="/qualtrics-16x16.svg" alt="Qualtrics" style={{ marginRight: '6px' }} />
        });
    }

    const createDisabled = isSubmitting || (screenerType == SCREENER_TYPE.QUALTRICS && !qualtricsSurveyId);

    function saveFormXHR(e) {
        e.preventDefault();

        setIsSubmitting(true);

        studies_service
            .createScreener(studyId, title, description, copyScreenerId, panelIdSelected, qualtricsSurveyId)
            .then(screener_response => {
                addToast('Created', {
                    appearance: 'success',
                    autoDismiss: true
                });

                history.push(`/studies/${studyId}/screeners/${screener_response.id}`);
            })
            .catch(error => {
                setIsSubmitting(false);
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    const getCopyScreenerDropdownItems = useMemo(() => {
        const items = [];
        items.push({
            id: 0,
            title: 'Do not copy'
        });

        if (allAccountScreeners) {
            allAccountScreeners
                .sort((a, b) => b.screener_id - a.screener_id)
                .forEach(s => {
                    let study_title = s.study_title;
                    if (study_title.length > 20) {
                        study_title = study_title.substr(0, 20) + '...';
                    }
                    items.push({
                        id: s.screener_id,
                        title: `[${study_title}] ${s.screener_title}`
                    });
                });
        }
        return items;
    }, [allAccountScreeners]);

    const getCopyScreenerDropdownValueMap = useMemo(() => {
        const items = {};
        items[0] = 'Do not copy';

        if (allAccountScreeners) {
            allAccountScreeners.forEach(s => {
                items[s.screener_id] = `[${s.study_title}] ${s.screener_title}`;
            });
        }
        return items;
    }, [allAccountScreeners]);

    function getScreenerTypeValueMap() {
        const items = {};
        screenerTypes.forEach(type => {
            items[type.id] = (
                <>
                    {type.icon}
                    {type.title}
                </>
            );
        });
        return items;
    }

    function onCreatePanel(panel) {
        addToast('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        refetchPanels().then(() => {
            setPanelIdSelected(panel.id);
        });
    }

    return (
        <>
            <ActionButton title="New Screener" action={() => setShowModal(true)} keyLabel="N" mr={0} />
            <Portal>
                <NiceModal
                    isOpen={showModal}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => setShowModal(false)}
                    style={{ content: { width: '510px' } }}
                    title="Create a new screener"
                    overflow="visible"
                >
                    <form onSubmit={saveFormXHR}>
                        {panels !== null && panelIdSelected !== null && (
                            <Box mb={3}>
                                <Label>Associated Panel</Label>
                                <PanelSelect
                                    width="100%"
                                    items={panels}
                                    value={panelIdSelected}
                                    onChange={panel_id => setPanelIdSelected(panel_id)}
                                    onCreatePanel={() => setShowModalCreatePanel(true)}
                                />
                            </Box>
                        )}

                        {screenerTypes.length > 1 && (
                            <>
                                <Label mt={3} htmlFor="description">
                                    Screener Tool
                                </Label>
                                <NiceSelect
                                    revealEllipsis={true}
                                    mimicSelectStyle={true}
                                    defaultValue={screenerType}
                                    positionRight="0px"
                                    items={screenerTypes}
                                    mapValueToLabel={getScreenerTypeValueMap()}
                                    onChange={setScreenerType}
                                />
                            </>
                        )}

                        {screenerType == SCREENER_TYPE.PANELFOX && (
                            <>
                                <Label mt={3} htmlFor="title">
                                    Internal Title
                                </Label>
                                <Input
                                    id="new-form-screener__title"
                                    type="text"
                                    name="title"
                                    placeholder="Online pre-screen"
                                    autoComplete="off"
                                    required
                                    autoFocus
                                    onChange={e => setTitle(e.target.value)}
                                />
                                <Label mt={3} htmlFor="description">
                                    Internal Description
                                </Label>
                                <Textarea
                                    id="new-form-screener__description"
                                    type="text"
                                    name="description"
                                    placeholder="Pre-screen for online shoppers"
                                    onChange={e => setDescription(e.target.value)}
                                />
                                <Label mt={3} htmlFor="description">
                                    Copy Screener
                                </Label>
                                <NiceSelect
                                    showSearch
                                    revealEllipsis={true}
                                    mimicSelectStyle={true}
                                    defaultValue={0}
                                    items={getCopyScreenerDropdownItems}
                                    mapValueToLabel={getCopyScreenerDropdownValueMap}
                                    onChange={setCopyScreenerId}
                                />
                            </>
                        )}

                        {screenerType == SCREENER_TYPE.QUALTRICS && (
                            <QualtricsSurveySelect
                                defaultValue={qualtricsSurveyId}
                                onChange={survey => {
                                    if (survey) {
                                        setQualtricsSurveyId(survey.id);
                                        setTitle(`Qualtrics: ${survey.name}`);
                                        setDescription(`External Qualtrics Survey: ${survey.name}`);
                                    } else {
                                        setQualtricsSurveyId(null);
                                    }
                                }}
                            />
                        )}

                        <Box className="modal-actions">
                            <Button variant="secondary-gray" type="button" mr={3} onClick={() => setShowModal(false)}>
                                Cancel
                            </Button>
                            <Button
                                mr={0}
                                type="submit"
                                variant="primary"
                                className="modal-primary"
                                disabled={createDisabled}
                            >
                                Create screener
                            </Button>
                        </Box>
                    </form>
                    <NiceModal
                        isOpen={showModalCreatePanel}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => setShowModalCreatePanel(false)}
                        title={`Create new panel`}
                    >
                        <CreatePanel onCreate={onCreatePanel} onClose={() => setShowModalCreatePanel(false)} />
                    </NiceModal>
                </NiceModal>
            </Portal>
        </>
    );
}

export default NewScreener;
