import React from 'react';
import { Helmet } from 'react-helmet';
import H1 from 'components/H1';
import H2 from 'components/H2';
import ReactTooltip from 'react-tooltip';
import TooltipIcon from 'components/TooltipIcon';
import config from 'config';
import xhr from 'xhr.js';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Box, Flex, Button } from 'rebass';
import { Label, Input, Select, Switch } from '@rebass/forms';
import { withToastManager } from 'react-toast-notifications';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import TimezoneDropdown from 'components/TimezoneDropdown';

import consts from 'consts';
import helpers from 'utils/helpers';
import services from 'services/services';
import user_services from 'services/users';
import auth from 'services/auth';
import { FiCheckCircle } from 'react-icons/fi';
import integrations from 'services/integrations';

class MePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timezones: {},
            user: this.props.auth.user,
            notification_settings: {},
            two_fa_code: null,
            loadingVerifyPhone: false
        };
        this.onFormChange = this.onFormChange.bind(this);
        this.disconnectGoogleCalendar = this.disconnectGoogleCalendar.bind(this);
        this.disconnectMicrosoftCalendar = this.disconnectMicrosoftCalendar.bind(this);
        this.disconnectZoom = this.disconnectZoom.bind(this);
        this.verifyPhoneXHR = this.verifyPhoneXHR.bind(this);
    }

    componentDidMount() {
        services.getTimezones().then(timezones => {
            this.setState({ timezones });
        });
        user_services.getNotificationSettings().then(notificationSettings => {
            this.setState({ notification_settings: notificationSettings });
        });
    }

    disconnectGoogleCalendar() {
        const { toastManager } = this.props;

        xhr.post(
            `/me/google-calendar/disconnect?account_id=${this.props.auth.account.id}`,
            {},
            {
                withCredentials: true
            }
        )
            .then(response => {
                const user = response.data;
                //console.log(user);
                this.setState({ user });
                toastManager.add('Successfully disconnected Google Calendar', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    disconnectMicrosoftCalendar() {
        const { toastManager } = this.props;

        xhr.post(
            `/me/microsoft-calendar/disconnect?account_id=${this.props.auth.account.id}`,
            {},
            {
                withCredentials: true
            }
        )
            .then(response => {
                const user = response.data;
                //console.log(user);
                this.setState({ user });
                toastManager.add('Successfully disconnected Microsoft Calendar', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    async disconnectZoom() {
        const { toastManager } = this.props;

        try {
            const user = await integrations.disconnectZoomUser();

            toastManager.add('Disconnected Zoom', {
                appearance: 'success',
                autoDismiss: true
            });

            this.setState({
                user
            });
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    onFormChange(e) {
        const { toastManager } = this.props;

        if (!e.target.name) {
            e.preventDefault();
            return;
        }
        const user = { ...this.state.user };
        user[e.target.name] = e.target.value;
        // console.log(e.target.name);
        this.setState({ user });

        // detect change, and clear timeout if exists
        if (this.saveDelay) {
            clearTimeout(this.saveDelay);
        }

        // set timer for 200ms
        this.saveDelay = setTimeout(function() {
            services
                .updateUser(user)
                .then(() => {
                    // this.setState({ user_xhr });

                    toastManager.add('Saved profile settings', {
                        appearance: 'success',
                        autoDismiss: true
                    });

                    auth.checkAuth();
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }, 500);
    }

    toggleNotification(key) {
        const { toastManager } = this.props;

        const { notification_settings } = this.state;
        const new_setting_value = !this.state.notification_settings[key];
        notification_settings[key] = new_setting_value;

        // set it now to make UI feel responsive
        this.setState({ notification_settings: notification_settings });

        user_services
            .updateNotificationSettings(notification_settings)
            .then(() => {
                toastManager.add('Notification updated: ' + this.state.notification_settings.labels[key], {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    renderNotificationSettings() {
        let ret = [];
        if (this.state.notification_settings && this.state.notification_settings.labels) {
            Object.keys(this.state.notification_settings.labels).forEach((key, index) => {
                // if the setting is for adding to the calendar.. don't show it if the user doesnt
                // want notifications about calendar events

                if (key == 'spot_add_to_calendar' && !this.state.notification_settings['spot_selected']) {
                    return;
                }

                ret.push(
                    <>
                        <Box className={`form-label ${index != 0 ? 'form-row-margin' : ''}`}>
                            {this.state.notification_settings.labels[key]}
                        </Box>
                        <Box className="form-data">
                            <Switch
                                className="switch-small"
                                name={this.state.notification_settings.labels[key]}
                                checked={this.state.notification_settings[key] ? true : false}
                                onClick={() => this.toggleNotification(key)}
                            />
                        </Box>
                    </>
                );
            });
        }
        return ret;
    }

    verifyPhoneXHR() {
        const { toastManager } = this.props;
        const that = this;
        this.setState({ loadingVerifyPhone: true });

        auth.verifyUserPhone(this.state.user.phone_number, this.state.two_fa_code)
            .then(response => {
                console.log('response', response);
                if (response && response.sent_two_fa) {
                    const two_fa_code = prompt('Please enter the code we sent to your phone via SMS:');
                    if (two_fa_code) {
                        this.setState({ two_fa_code: two_fa_code.trim() }, () => {
                            that.verifyPhoneXHR();
                        });
                    } else {
                        this.setState({ loadingVerifyPhone: false, two_fa_code: null });
                    }
                } else {
                    toastManager.add('Successfully verified phone number', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    this.setState({ loadingVerifyPhone: false, two_fa_code: null, user: response });
                }
            })
            .catch(error => {
                this.setState({ loadingVerifyPhone: false, two_fa_code: null });
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    renderConnectedLabel() {
        return (
            <Box fontSize={1} style={{ marginTop: '6px' }} mr={3} className="green">
                <FiCheckCircle /> Connected
            </Box>
        );
    }

    render() {
        const isAccountZoomConnected = !!this.props.auth.account.zoom_account_id;

        return (
            <AppPageWrapper>
                <Helmet>
                    <title>User Settings</title>
                </Helmet>

                <AppPageWrapperSectionHeader className="experience">
                    <Box mt={4}>
                        <H1>User Settings</H1>
                        <Box className="experience-description text-secondary-dark">
                            Configure your personal settings.
                        </Box>
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box mt={4} mx={4} flex="1 1 0%">
                        <Box className="form-wrapper2 form-wrapper2-border">
                            <Flex className="settings-header">
                                <Box className="form-data">
                                    <H2>Profile</H2>
                                    <Box className={`form-section-information`}>
                                        Basic personal and security information.
                                    </Box>
                                </Box>
                            </Flex>
                            <Box>
                                <Flex mb={3}>
                                    {this.state.user.avatar && (
                                        <Box mr={3}>
                                            <img
                                                src={this.state.user.avatar}
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    borderRadius: '50%'
                                                }}
                                                alt="Avatar"
                                            />
                                        </Box>
                                    )}
                                    <Box sx={{ whiteSpace: 'nowrap' }} className="text-primary">
                                        <div>{this.state.user.name}</div>
                                        <div className="fs-12 text-secondary-dark">{this.state.user.email}</div>
                                    </Box>
                                </Flex>
                                <Box className="form-label form-row-margin">Your Name</Box>
                                <Box className="form-data">
                                    <Input
                                        type="text"
                                        name="name"
                                        value={this.state.user.name}
                                        onChange={this.onFormChange}
                                    />
                                </Box>
                                <Box className="form-label form-row-margin">Timezone</Box>
                                <Box className="form-data">
                                    <TimezoneDropdown
                                        timezones={this.state.timezones}
                                        timezoneSelected={this.state.user.timezone}
                                        onChange={tzVal => {
                                            this.onFormChange({ target: { name: 'timezone', value: tzVal } });
                                        }}
                                    />
                                </Box>

                                <Box className="form-label form-row-margin">Phone Number</Box>
                                <Box className="form-data">
                                    <PhoneInput
                                        placeholder={'1 (123) 123-4567'}
                                        value={this.state.user.phone_number}
                                        onChange={phone => {
                                            this.onFormChange({ target: { name: 'phone_number', value: phone } });
                                        }}
                                        inputClass="theme-input"
                                        inputStyle={{ border: 0, maxWidth: '240px' }}
                                    />
                                    {this.state.user.phone_number &&
                                    this.state.user.phone_number != this.state.user.phone_number_verified ? (
                                        <Box fontSize={1} mt={3}>
                                            <Button
                                                type="button"
                                                variant="primary"
                                                className="modal-primary"
                                                disabled={this.state.loadingVerifyPhone}
                                                onClick={this.verifyPhoneXHR}
                                            >
                                                {this.state.loadingVerifyPhone
                                                    ? 'Sending code...'
                                                    : 'Verify phone number'}
                                            </Button>
                                        </Box>
                                    ) : this.state.user.phone_number &&
                                      this.state.user.phone_number == this.state.user.phone_number_verified ? (
                                        <Box fontSize={1} mt={2}>
                                            <FiCheckCircle /> <small>Phone verified</small>
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                                <Box className="form-label form-row-margin">Two-Factor Authentication</Box>
                                <Box className="form-data">
                                    {this.state.user.phone_number &&
                                    this.state.user.phone_number.length > 5 &&
                                    this.state.user.phone_number == this.state.user.phone_number_verified ? (
                                        <Switch
                                            style={{ marginTop: '5px' }}
                                            className="switch-small"
                                            name={this.state.user.two_fa}
                                            checked={this.state.user.two_fa ? true : false}
                                            onClick={onOff => {
                                                this.onFormChange({
                                                    target: { name: 'two_fa', value: !this.state.user.two_fa }
                                                });
                                            }}
                                        />
                                    ) : (
                                        <Box fontSize={1} style={{ marginTop: '3px' }}>
                                            Enter and verify your phone number to enable 2FA
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box className="form-wrapper2 form-wrapper2-border">
                            <Flex className="settings-header">
                                <Box className="form-data">
                                    <H2>Notifications</H2>
                                    <Box className={`form-section-information`}>
                                        Select which email notifications you want to recieve.
                                    </Box>
                                </Box>
                            </Flex>
                            <Box>{this.renderNotificationSettings()}</Box>
                        </Box>
                        <Box className="form-wrapper2 form-wrapper2-border" id="calendar-integrations">
                            <Flex className="settings-header">
                                <Box className="form-data">
                                    <H2>Integrations</H2>
                                    <Box className={`form-section-information`}>
                                        Integrate your personal calendar for scheduling availability, or many other
                                        features.
                                    </Box>
                                </Box>
                            </Flex>
                            <Box width={'100%'}>
                                <Flex justifyContent="space-between" width="100%">
                                    <Box className="form-label" flexGrow={1}>
                                        <img
                                            src="/google-calendar.png"
                                            style={{ height: '24px', marginRight: '4px' }}
                                        />
                                        Google Calendar & Meet
                                    </Box>
                                    <Box>
                                        {this.state.user.google_calendar == 0 ? (
                                            <Button
                                                type="button"
                                                variant="secondary"
                                                mr={0}
                                                className="modal-secondary"
                                                onClick={e => {
                                                    window.location.href =
                                                        config.API_URL +
                                                        `/me/google-calendar/connect?account_id=${this.props.auth.account.id}&login_hint=${this.props.auth.user.email}`;
                                                }}
                                            >
                                                Connect
                                            </Button>
                                        ) : (
                                            <Flex style={{ justifyContent: 'space-between' }}>
                                                {this.renderConnectedLabel()}
                                                <Box style={{ marginTop: '2px' }}>
                                                    <Button
                                                        type="button"
                                                        variant="secondary-gray"
                                                        onClick={e => {
                                                            this.disconnectGoogleCalendar();
                                                        }}
                                                    >
                                                        Disconnect
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        )}
                                    </Box>
                                </Flex>
                                <Flex justifyContent="space-between" width="100%" className="form-row-margin">
                                    <Box className="form-label" flexGrow={1}>
                                        <img
                                            src="/outlook-calendar.png"
                                            style={{ height: '24px', marginRight: '4px' }}
                                        />
                                        Outlook Calendar & MS Teams
                                    </Box>
                                    <Box>
                                        {this.state.user.microsoft_calendar == 0 ? (
                                            <Button
                                                type="button"
                                                variant="secondary"
                                                mr={0}
                                                className="modal-secondary"
                                                onClick={e => {
                                                    window.location.href =
                                                        config.API_URL +
                                                        `/me/microsoft-calendar/connect?account_id=${this.props.auth.account.id}&login_hint=${this.props.auth.user.email}`;
                                                }}
                                            >
                                                Connect
                                            </Button>
                                        ) : (
                                            <Flex style={{ justifyContent: 'space-between' }}>
                                                {this.renderConnectedLabel()}
                                                <Box style={{ marginTop: '2px' }}>
                                                    <Button
                                                        type="button"
                                                        variant="secondary-gray"
                                                        onClick={e => {
                                                            this.disconnectMicrosoftCalendar();
                                                        }}
                                                    >
                                                        Disconnect
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        )}
                                    </Box>
                                </Flex>
                                <Flex justifyContent="space-between" width="100%" className="form-row-margin">
                                    <Box className="form-label" flexGrow={1}>
                                        <img src="/zoom.png" style={{ height: '24px', marginRight: '4px' }} />
                                        Zoom
                                    </Box>
                                    <Box>
                                        {!this.state.user.zoom_account_id ? (
                                            <>
                                                <Button
                                                    type="button"
                                                    variant="secondary"
                                                    mr={0}
                                                    className="modal-secondary"
                                                    disabled={isAccountZoomConnected}
                                                    data-tip
                                                    data-for={`zoom-integration-disabled`}
                                                    onClick={e => {
                                                        window.location.href = window.location =
                                                            consts.zoomAuthorizationUrl;
                                                    }}
                                                >
                                                    Connect
                                                </Button>
                                                {isAccountZoomConnected && (
                                                    <ReactTooltip
                                                        id={`zoom-integration-disabled`}
                                                        effect="solid"
                                                        place="right"
                                                    >
                                                        Your Organization is currently integrated with Zoom.
                                                        <br />
                                                        To switch to User integration, please go to
                                                        <br />
                                                        Settings &gt; Integrations and disconnect Zoom integration.
                                                    </ReactTooltip>
                                                )}
                                            </>
                                        ) : (
                                            <Flex style={{ justifyContent: 'space-between' }}>
                                                {this.renderConnectedLabel()}
                                                <Box style={{ marginTop: '2px' }}>
                                                    <Button
                                                        type="button"
                                                        variant="secondary-gray"
                                                        onClick={e => {
                                                            this.disconnectZoom();
                                                        }}
                                                    >
                                                        Disconnect
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        )}
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                        <br />
                        <br />
                    </Box>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        );
    }
}

export default withToastManager(MePage);
