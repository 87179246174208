import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import H1 from 'components/H1';
import H2 from 'components/H2';
import { Flex, Box, Button } from 'rebass';
import { Input, Label, Switch, Select, Radio } from '@rebass/forms';
import {
    FiTrash,
    FiPlus,
    FiUserPlus,
    FiTrash2,
    FiAlertCircle,
    FiLink,
    FiRefreshCw,
    FiExternalLink
} from 'react-icons/fi';
import service_accounts from 'services/accounts';
import service_people from 'services/people';
import services from 'services/services';
import helpers from 'utils/helpers';
import helpersScreener from 'utils/helpers-screener';
import { FORM_TYPE_STANDARD, FORM_TYPE_CONSENT, PANEL_DELETE_PANELISTS } from 'utils/constants';
import { Link } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

import BreadcrumbBack from 'components/BreadcrumbBack';
import ParticipantsPageSubNav from 'containers/ParticipantsPage/ParticipantsPageSubNav';
import BreadcrumbDivider from 'components/Breadcrumb/BreadcrumbDivider';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import SideNav from 'components/SideNav';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import NiceDropdown from 'components/NiceDropdown';
import Badge from 'components/Badge';
import DeletePanelistsModal from 'components/DeletePanelistsModal';

function ParticipantsPanelsEditPage(props) {
    const { addToast } = useToasts();
    const history = useHistory();

    const [isDeletePanelistsModalOpen, setIsDeletePanelistsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [panel, setPanel] = useState(null);
    const [deletePanelists, setDeletePanelists] = useState(null);
    const [showCollaboratorDropdown, setShowCollaboratorDropdown] = useState(false);
    const [showConsentFormsDropdown, setShowConsentFormsDropdown] = useState(false);
    const [showSignupFormsDropdown, setShowSignupFormsDropdown] = useState(false);
    const [accountUsers, setAccountUsers] = useState([]);
    const [accountForms, setAccountForms] = useState([]);

    const { data: emailTemplates } = useQuery({
        queryFn: () => service_accounts.getEmailTemplates(),
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    const propertiesRef = useRef();
    const deletePanelRef = useRef();
    const rbacRef = useRef();
    const dataCollectionRef = useRef();

    // panelCollaborators is created as an array of accountUsers that are either admins or collaborators on the panel.
    // We sort the array so that admins are at the top of the list.
    // Then panelCollaboratorsIds is saved as set of ids to simplify the process of checking whether a user is already a collaborator.
    // This makes filtering the list of collaborators that we can still add to the panel easier and faster.
    const collaboratorsIds =
        panel && panel.collaborators ? new Set(panel.collaborators.map(c => c.user_id)) : new Set([]);
    const panelCollaborators =
        panel && panel.rbac_restricted
            ? accountUsers.filter(au => au.type === 'admin' || collaboratorsIds.has(au.user.id))
            : [];
    panelCollaborators.sort((a, b) => {
        if (a.type === b.type) return 0;
        if (a.type === 'admin') return -1;
        return 1;
    });
    const panelCollaboratorsIds = new Set(panelCollaborators.map(c => c.user.id));

    useEffect(() => {
        getPanelXHR();
        getAccountUsers();
        getAccountForms();
    }, []);

    const getAccountForms = () => {
        service_people
            .getPanelSignupForms()
            .then(accountForms => {
                setAccountForms(accountForms);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    const getAccountUsers = () => {
        services
            .getAccountUsers()
            .then(account_users => {
                setAccountUsers(account_users);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    const getPanelXHR = () => {
        const panel_id = props.match.params.panel_id;

        service_people
            .getPanel(panel_id)
            .then(panel_response => {
                setPanel(panel_response);
                setIsLoading(false);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
                setIsLoading(false);
            });
    };

    const savePanelXHR = panel => {
        service_people
            .updatePanel(panel)
            .then(() => {
                addToast('Saved', {
                    appearance: 'info',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    const debouncedSavePanelXHR = useCallback(
        debounce(panel => savePanelXHR(panel), 300),
        []
    );

    const onPropertiesChange = e => {
        const { name, value } = e.target;
        const newPanel = { ...panel };
        newPanel[name] = value;
        setPanel(newPanel);
        debouncedSavePanelXHR(newPanel);
    };

    const deletePanelXHR = (panel_id, delete_panelists) => {
        service_people
            .deletePanel(panel_id, delete_panelists)
            .then(() => {
                addToast('Panel deleting has been started.', {
                    appearance: 'success',
                    autoDismiss: true
                });
                setPanel(null);
                history.push('/panels');
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    /**
     * Open the delete panelists modal
     */
    function onDeletePanelitsModalOpen() {
        setIsDeletePanelistsModalOpen(true);
    }

    /**
     * Close the delete panelists modal
     */
    function onDeletePanelitsModalClose() {
        setIsDeletePanelistsModalOpen(false);
    }

    const onDeletePanel = () => {
        if ([PANEL_DELETE_PANELISTS.CURRENT, PANEL_DELETE_PANELISTS.ALL].includes(deletePanelists)) {
            // the radio button is selected, so we need to open the modal
            onDeletePanelitsModalOpen();
        } else {
            const confirmDeleteText = 'Are you sure you want to delete this panel?';
            const confirmDeleteResult = confirm(confirmDeleteText);

            if (confirmDeleteResult) {
                deletePanelXHR(panel.id, 0);
            } else {
                addToast('Process was cancelled - delete action was NOT taken.', {
                    appearance: 'error',
                    autoDismiss: true
                });
            }
        }
    };

    const onRemoveUser = user_id => {
        if (confirm('Are you sure you want to remove this user?')) {
            const panel_id = props.match.params.panel_id;

            service_people
                .removeUsersFromPanel(panel_id, [user_id])
                .then(panel_users => {
                    const newPanel = { ...panel };
                    newPanel.collaborators = panel_users;
                    setPanel(newPanel);

                    addToast('Successfully removed user', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    };

    const onAddUser = user_id => {
        const panel_id = props.match.params.panel_id;

        service_people
            .addUsersToPanel(panel_id, [user_id])
            .then(panel_users => {
                const newPanel = { ...panel };
                newPanel.collaborators = panel_users;
                setPanel(newPanel);

                addToast('Successfully added user', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    const onAddForm = form_id => {
        const panel_id = props.match.params.panel_id;

        service_people
            .addFormsToPanel(panel_id, [form_id])
            .then(panelForms => {
                const newPanel = { ...panel };
                newPanel.forms = panelForms;
                setPanel(newPanel);

                addToast('Successfully added form', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    const onRemoveForm = form_id => {
        if (confirm('Are you sure you want to remove this form?')) {
            const panel_id = props.match.params.panel_id;

            service_people
                .removeFormsFromPanel(panel_id, [form_id])
                .then(panelForms => {
                    const newPanel = { ...panel };
                    newPanel.forms = panelForms;
                    setPanel(newPanel);

                    addToast('Successfully removed form', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    };

    const addFormsStickyButton = (
        <>
            <Flex justifyContent="space-between">
                <Button
                    type="button"
                    variant="secondary"
                    className="secondary-icon"
                    mr={3}
                    onClick={() => getAccountForms()}
                >
                    <FiRefreshCw />
                </Button>
                <a href="/settings/forms" target="_blank" style={{ flexGrow: 1 }}>
                    <Button type="button" variant="secondary" style={{ width: '100%' }}>
                        + Create Form
                    </Button>
                </a>
            </Flex>
        </>
    );
    const renderAddConsentForms = () => {
        return (
            <Box sx={{ position: 'relative' }} mt={3}>
                <Button type="button" variant="secondary" onClick={() => setShowConsentFormsDropdown(true)}>
                    <FiPlus /> Add Consent Forms
                </Button>
                {showConsentFormsDropdown && (
                    <NiceDropdown
                        showSearch
                        onClose={() => setShowConsentFormsDropdown(false)}
                        items={accountForms
                            // only show consent forms
                            .filter(form => form.form_type == FORM_TYPE_CONSENT.id)
                            // remove already-attached forms
                            .filter(form => {
                                return !addedConsentForms.map(addedForm => addedForm.id).includes(form.id);
                            })
                            .map(form => ({
                                title: form.title,
                                id: form.id
                            }))}
                        onChange={form_id => {
                            onAddForm(form_id);
                        }}
                        renderBottomStickyButton={addFormsStickyButton}
                    />
                )}
            </Box>
        );
    };

    const renderSignupConsentForms = () => {
        return (
            <Box sx={{ position: 'relative' }} mt={3}>
                <Button type="button" variant="secondary" onClick={() => setShowSignupFormsDropdown(true)}>
                    <FiPlus /> Add Signup Forms
                </Button>
                {showSignupFormsDropdown && (
                    <NiceDropdown
                        showSearch
                        onClose={() => setShowSignupFormsDropdown(false)}
                        items={accountForms
                            // only show consent forms
                            .filter(form => form.form_type == FORM_TYPE_STANDARD.id)
                            // remove already-attached forms
                            .filter(form => {
                                return !addedSignupForms.map(addedForm => addedForm.id).includes(form.id);
                            })
                            .map(form => ({
                                title: form.title,
                                id: form.id
                            }))}
                        onChange={form_id => {
                            onAddForm(form_id);
                        }}
                        renderBottomStickyButton={addFormsStickyButton}
                    />
                )}
            </Box>
        );
    };

    const addedConsentForms = panel ? panel.forms.filter(form => form.form_type == FORM_TYPE_CONSENT.id) : [];
    const addedSignupForms = panel ? panel.forms.filter(form => form.form_type == FORM_TYPE_STANDARD.id) : [];

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Edit panel</title>
                </Helmet>

                {/*<Flex>
                    <ParticipantsPageSubNav />
                </Flex>*/}

                <AppPageWrapperSectionHeader className="experience experience-border experience-no-subnav">
                    <Box>
                        <BreadcrumbBack to="/panels">Back to all panels</BreadcrumbBack>
                        <H1>{panel ? `${panel.title} Settings` : isLoading ? '' : 'Not Found'}</H1>
                    </Box>
                </AppPageWrapperSectionHeader>

                <AppPageWrapperSectionBody>
                    {isLoading ? (
                        <div style={{ width: '100%' }}>
                            <LoadingWrapper>
                                <LoadingIndicator />
                            </LoadingWrapper>
                        </div>
                    ) : !panel ? (
                        <Flex mt={3} mx={4}>
                            Panel not found.
                        </Flex>
                    ) : (
                        <Box mx={4} my={4} width="100%">
                            <Flex flex="1 1 0%" justifyContent="space-between">
                                <Box flex="0 0 auto">
                                    <Box id="properties" ref={propertiesRef} className="form-wrapper2" pb={4}>
                                        <Box className="settings-header">
                                            <H2>General</H2>
                                            <Box className="form-section-information">Information about the panel.</Box>
                                        </Box>
                                        <Box className="form-data">
                                            <Box className="form-label">Title</Box>
                                            <Box>
                                                <Input
                                                    type="text"
                                                    name="title"
                                                    value={panel.title}
                                                    onChange={onPropertiesChange}
                                                />
                                            </Box>
                                            {/*<Box className="form-label" mt={3}>
                                                Created At
                                            </Box>
                                            <Box>{moment.utc(panel.created_at).format('MMMM D, YYYY')}</Box>
                                            <Box className="form-label" mt={3}>
                                                Updated At
                                            </Box>
                                            <Box>{moment.utc(panel.updated_at).format('MMMM D, YYYY')}</Box>
                                            */}
                                            <Box className="form-label form-row-margin">
                                                Welcome Email{' '}
                                                <a
                                                    href="#"
                                                    data-beacon-article="63125b95037bc877147b5a3d"
                                                    className="help-question"
                                                >
                                                    ?
                                                </a>
                                            </Box>
                                            <Box className="form-data">
                                                <Select
                                                    name="email_template_id"
                                                    mt="1px"
                                                    onChange={onPropertiesChange}
                                                    value={panel.email_template_id}
                                                >
                                                    <option value=""> Do not send welcome email</option>
                                                    {emailTemplates && (
                                                        <>
                                                            {emailTemplates.map(emailTemplate => (
                                                                <option
                                                                    key={emailTemplate.id}
                                                                    value={emailTemplate.id}
                                                                    disabled={!emailTemplate.subject}
                                                                >
                                                                    {!emailTemplate.subject && '**Please add subject**'}
                                                                    {emailTemplate.title}
                                                                </option>
                                                            ))}
                                                            {emailTemplates.length === 0 && (
                                                                <option disabled>
                                                                    Go to Settings > Email Templates to create a
                                                                    Template
                                                                </option>
                                                            )}
                                                        </>
                                                    )}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        id="data-collection"
                                        ref={dataCollectionRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Box className="settings-header">
                                            <H2>Data Collection</H2>
                                            <Box className="form-section-information">
                                                Responents who fill out these forms will be added to this panel.
                                                <Box fontSize={1} sx={{ paddingTop: '10px' }}>
                                                    <Link to="/settings/forms" target="_blank" className="medium">
                                                        View All Forms <FiExternalLink />
                                                    </Link>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="form-data">
                                            <Box className="">
                                                <Box className="form-label form-label-border bold">SIGNUP FORMS</Box>
                                                {addedSignupForms.length ? (
                                                    addedSignupForms.map(form => {
                                                        return (
                                                            <Flex
                                                                mb={2}
                                                                justifyContent={'space-between'}
                                                                key={form.id}
                                                                className="border-bottom-gray row-hover"
                                                                alignItems="center"
                                                                p="8px 0"
                                                            >
                                                                <Box className="">
                                                                    <a
                                                                        href={`/settings/forms/${form.id}`}
                                                                        className="medium text-primary"
                                                                        target="_blank"
                                                                    >
                                                                        {form.title} <FiExternalLink />
                                                                    </a>
                                                                </Box>
                                                                <Box>
                                                                    <a
                                                                        href={helpersScreener.getPanelSignupLink(
                                                                            props.auth.account,
                                                                            form.links,
                                                                            form.id
                                                                        )}
                                                                        target="_blank"
                                                                    >
                                                                        <Button variant="secondary-gray">
                                                                            <FiLink /> Preview
                                                                        </Button>
                                                                    </a>
                                                                    <Button
                                                                        variant="secondary-gray"
                                                                        className="secondary-icon"
                                                                        ml={2}
                                                                        onClick={() => {
                                                                            onRemoveForm(form.id);
                                                                        }}
                                                                    >
                                                                        <FiTrash2 />
                                                                    </Button>
                                                                </Box>
                                                            </Flex>
                                                        );
                                                    })
                                                ) : (
                                                    <Box mt={3}>
                                                        <Badge type="danger">
                                                            <FiAlertCircle /> No forms linked
                                                        </Badge>
                                                    </Box>
                                                )}
                                                {renderSignupConsentForms()}

                                                <Box mt={4} className="form-label form-label-border bold">
                                                    CONSENT FORMS
                                                </Box>
                                                {addedConsentForms.length ? (
                                                    addedConsentForms.map(form => {
                                                        return (
                                                            <Flex
                                                                mb={2}
                                                                justifyContent={'space-between'}
                                                                key={form.id}
                                                                className="border-bottom-gray row-hover"
                                                                alignItems="center"
                                                                p="8px 0"
                                                            >
                                                                <Box className="">
                                                                    <a
                                                                        href={`/settings/forms/${form.id}`}
                                                                        className="medium text-primary"
                                                                        target="_blank"
                                                                    >
                                                                        {form.title} <FiExternalLink />
                                                                    </a>
                                                                </Box>
                                                                <Box>
                                                                    <a
                                                                        href={helpersScreener.getPanelSignupLink(
                                                                            props.auth.account,
                                                                            form.links,
                                                                            form.id
                                                                        )}
                                                                        target="_blank"
                                                                    >
                                                                        <Button variant="secondary-gray">
                                                                            <FiLink /> Preview
                                                                        </Button>
                                                                    </a>
                                                                    <Button
                                                                        variant="secondary-gray"
                                                                        className="secondary-icon"
                                                                        ml={2}
                                                                        onClick={() => {
                                                                            onRemoveForm(form.id);
                                                                        }}
                                                                    >
                                                                        <FiTrash2 />
                                                                    </Button>
                                                                </Box>
                                                            </Flex>
                                                        );
                                                    })
                                                ) : (
                                                    <Box mt={3}>0 consent forms</Box>
                                                )}
                                                {renderAddConsentForms()}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box id="rbac" ref={rbacRef} className="form-wrapper2 form-wrapper2-border">
                                        <Box className="settings-header">
                                            <H2>Permissions</H2>
                                            <Box className="form-section-information">
                                                Manage user access to this panel.
                                            </Box>
                                        </Box>
                                        <Box className="form-data">
                                            <Switch
                                                className="switch-small"
                                                name="rbac_restricted"
                                                checked={panel.rbac_restricted ? true : false}
                                                onClick={() =>
                                                    onPropertiesChange({
                                                        target: {
                                                            name: 'rbac_restricted',
                                                            value: panel.rbac_restricted ? 0 : 1
                                                        }
                                                    })
                                                }
                                            />
                                            <span className="text-primary switch-label">
                                                Only allow certain users to access this panel
                                            </span>

                                            {panel.rbac_restricted == true && (
                                                <>
                                                    <Box mt={2}>
                                                        {panelCollaborators.map(c => {
                                                            return (
                                                                <Box key={c.id} className="row-hover row-basic-grid">
                                                                    <Flex>
                                                                        <span
                                                                            className="black"
                                                                            style={{
                                                                                flexGrow: 1,
                                                                                marginTop: '6px',
                                                                                display: 'inline-block',
                                                                                verticalAlign: 'top'
                                                                            }}
                                                                        >
                                                                            {c.user.name}
                                                                        </span>
                                                                        {c.type === 'admin' ? (
                                                                            <>
                                                                                <Label
                                                                                    data-tip
                                                                                    data-for={`tooltip-admin-${c.user_id}`}
                                                                                    className={`label-basic label-purple`}
                                                                                    style={{
                                                                                        margin: '5.5px 0',
                                                                                        width: '60px'
                                                                                    }}
                                                                                >
                                                                                    Admin
                                                                                </Label>
                                                                                <ReactTooltip
                                                                                    id={`tooltip-admin-${c.user_id}`}
                                                                                    effect="solid"
                                                                                    place="left"
                                                                                >
                                                                                    <Box>
                                                                                        Admins have access to all
                                                                                        restricted panels and their
                                                                                        <br />
                                                                                        permissions cannot be taken
                                                                                        away.
                                                                                    </Box>
                                                                                </ReactTooltip>
                                                                            </>
                                                                        ) : (
                                                                            <Button
                                                                                ml={2}
                                                                                type="button"
                                                                                key={`${c.user_id}delete`}
                                                                                variant="secondary-gray"
                                                                                className="secondary-icon"
                                                                                onClick={() => onRemoveUser(c.user_id)}
                                                                            >
                                                                                <FiTrash2 />
                                                                            </Button>
                                                                        )}
                                                                    </Flex>
                                                                </Box>
                                                            );
                                                        })}
                                                    </Box>
                                                    <Box sx={{ position: 'relative' }} mt={3}>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            onClick={() => setShowCollaboratorDropdown(true)}
                                                            mr={0}
                                                        >
                                                            <FiPlus /> Add User
                                                        </Button>
                                                        {showCollaboratorDropdown && (
                                                            <NiceDropdown
                                                                showSearch
                                                                onClose={() => setShowCollaboratorDropdown(false)}
                                                                items={accountUsers
                                                                    // remove users already with access
                                                                    .filter(au => {
                                                                        return !panelCollaboratorsIds.has(au.user.id);
                                                                    })
                                                                    // render
                                                                    .map(au => ({
                                                                        title: au.user.name,
                                                                        id: au.user.id
                                                                    }))}
                                                                onChange={user_id => {
                                                                    onAddUser(user_id);
                                                                    // updateHeaderTitlesToColumnIds(showDisplayedCustomColumn, columnId);
                                                                }}
                                                                renderBottomStickyButton={
                                                                    <Button
                                                                        type="button"
                                                                        variant="secondary"
                                                                        onClick={() => {
                                                                            window.location = '/settings/users';
                                                                        }}
                                                                    >
                                                                        <FiUserPlus /> Invite users
                                                                    </Button>
                                                                }
                                                            />
                                                        )}
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box
                                        id="delete-panel"
                                        ref={deletePanelRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Box className="settings-header">
                                            <H2>Delete Panel</H2>
                                            <Box className="form-section-information">
                                                Permanently delete this panel.{' '}
                                                <a
                                                    href="#"
                                                    data-beacon-article="674e0b40689cb2718737233e"
                                                    className="help-question"
                                                >
                                                    ?
                                                </a>
                                            </Box>
                                        </Box>
                                        <Box className="form-data">
                                            {panel.panelists_count > 0 && (
                                                <>
                                                    <Box className="form-label">
                                                        There {panel.panelists_count == 1 ? 'is' : 'are'}{' '}
                                                        {helpers.numberFormat(panel.panelists_count)}{' '}
                                                        {panel.panelists_count > 1 ? 'panelists' : 'panelist'} in this
                                                        panel:
                                                    </Box>
                                                    <Label style={{ cursor: 'pointer', fontWeight: 400 }}>
                                                        <Radio
                                                            name="delete-panelists"
                                                            checked={deletePanelists === PANEL_DELETE_PANELISTS.NONE}
                                                            onClick={() => {
                                                                setDeletePanelists(PANEL_DELETE_PANELISTS.NONE);
                                                            }}
                                                        />
                                                        Remove from this panel, but don't delete the panelists.
                                                    </Label>
                                                    {panel.panelists_to_delete_count > 0 && (
                                                        <Label style={{ cursor: 'pointer', fontWeight: 400 }}>
                                                            <Radio
                                                                name="delete-panelists"
                                                                checked={
                                                                    deletePanelists === PANEL_DELETE_PANELISTS.CURRENT
                                                                }
                                                                onClick={() => {
                                                                    setDeletePanelists(PANEL_DELETE_PANELISTS.CURRENT);
                                                                }}
                                                            />
                                                            Remove from this panel, delete{' '}
                                                            {helpers.numberFormat(panel.panelists_to_delete_count)} who{' '}
                                                            {panel.panelists_to_delete_count == 1 ? 'is' : 'are'} in no
                                                            other panels.
                                                        </Label>
                                                    )}
                                                    <Label style={{ cursor: 'pointer', fontWeight: 400 }}>
                                                        <Radio
                                                            name="delete-panelists"
                                                            checked={deletePanelists == PANEL_DELETE_PANELISTS.ALL}
                                                            onClick={() => {
                                                                setDeletePanelists(PANEL_DELETE_PANELISTS.ALL);
                                                            }}
                                                        />
                                                        Permanently delete from all panels.
                                                    </Label>
                                                </>
                                            )}
                                            <Button
                                                type="button"
                                                variant="danger"
                                                onClick={onDeletePanel}
                                                mt={2}
                                                disabled={panel.panelists_count > 0 && deletePanelists === null}
                                            >
                                                <FiTrash /> Delete panel
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex="0 0 auto">
                                    <SideNav
                                        sections={[
                                            {
                                                id: 'properties',
                                                ref: propertiesRef,
                                                label: 'General',
                                                display: true
                                            },
                                            {
                                                id: 'data-collection',
                                                ref: dataCollectionRef,
                                                label: 'Data Collection',
                                                display: true
                                            },
                                            {
                                                id: 'rbac',
                                                ref: rbacRef,
                                                label: 'Permissions',
                                                display: true
                                            },
                                            {
                                                id: 'delete-panel',
                                                ref: deletePanelRef,
                                                label: 'Delete Panel',
                                                display: true
                                            }
                                        ]}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                    )}
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
            {panel && (
                <DeletePanelistsModal
                    deletePanelists={deletePanelists}
                    isOpen={isDeletePanelistsModalOpen}
                    onClose={onDeletePanelitsModalClose}
                    count={panel.panelists_count}
                    countDelete={panel.panelists_to_delete_count}
                    onDelete={() => deletePanelXHR(panel.id, deletePanelists)}
                />
            )}
        </Flex>
    );
}

export default ParticipantsPanelsEditPage;
