import React from 'react';
import xhr from 'xhr.js';
import qs from 'qs';
import config from 'config';
import authService from 'services/auth';
import services from 'services/services';
import {
    COL_CREATED_AT,
    COL_OPT_IN,
    COL_OPT_IN_SMS,
    COL_UPDATED_AT,
    FALSY_FORM_VALUES,
    FORM_TYPES,
    STUDY_LOCATION_TYPE,
    TRUTHY_FORM_VALUES
} from 'utils/constants';
import moment from 'moment-timezone';
import { FiInfo, FiUser, FiArrowRight, FiPlayCircle, FiBriefcase, FiSlash, FiAlertTriangle } from 'react-icons/fi';
import { Redirect, Link } from 'react-router-dom';
import { Flex, Box, Button } from 'rebass';
import { Label } from '@rebass/forms';
import store from 'store';
import { datadogRum } from '@datadog/browser-rum';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { CURRENCY_SYMBOL, CURRENCY_POINTS, SCREENER_QUOTA_TYPES, PAID_FEATURE, COL_BLOCKLIST } from 'utils/constants';
import { BlockedBadge } from 'components/BlockedBadge';
import { NotificationCard } from 'components/NotificationCard';
import Badge from 'components/Badge';
import xss from 'xss';

const helpers = {
    ignoreNonNumericInput(event) {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    },
    getAccountTitleForOgTag(account) {
        if (account && account.title) {
            return account.title;
        } else {
            return 'Panelfox';
        }
    },
    minutesToHoursPretty(a) {
        var hours = Math.trunc(a / 60);
        var minutes = a % 60;
        return (hours ? hours + 'hr ' : '') + (minutes ? minutes + 'min' : '');
    },
    /**
     * Opens the support chat with a message
     *
     * @param {Object} [message] The message to prefill the chat with
     * @param {String} message.subject The subject of the message
     * @param {String} message.text The text of the message
     */
    openSupportChat(message) {
        const beaconInfo = window.Beacon('info');
        if (beaconInfo) {
            // If message is provided, prefill the message
            if (message && typeof message === 'object')
                window.Beacon('prefill', {
                    subject: message.subject,
                    text: message.text
                });

            window.Beacon('open');
            window.Beacon('navigate', '/ask/'); // Ask screen
        }
    },
    getPercentage(partialValue, totalValue, decimals) {
        if (!totalValue || !partialValue) return 0;

        if (decimals) {
            return parseFloat((partialValue / totalValue) * 100).toFixed(decimals);
        }

        return Math.round((partialValue / totalValue) * 100);
    },
    getScreenerQuotaTypeHumanReadable(type_value) {
        let found = SCREENER_QUOTA_TYPES.find(sqt => sqt.value == type_value);
        if (found) {
            return found.label;
        }
    },
    redirectAfterPause(link) {
        // redirect after N seconds
        setTimeout(() => {
            window.location = link;
        }, 2 * 1000);
    },
    setPanelistJWTToken(token) {
        store.set('panelist_jwt_token', token);
    },
    // returns a token string (not full object)
    getPanelistJWTToken() {
        const jwt_token_object = store.get('panelist_jwt_token');
        let token;
        if (jwt_token_object && jwt_token_object.access_token) {
            token = jwt_token_object.access_token;
        }
        return token;
    },
    getPageImage(account) {
        if (account) {
            return account && account.logo ? account.logo : null;
        }
    },
    is_iOS() {
        return (
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
                navigator.platform
            ) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    },
    getPanelistLogoHeight(account) {
        let height = '80px';
        if (account && account.public_settings && account.public_settings.logo_height) {
            height = `${account.public_settings.logo_height}px`;
        }
        return height;
    },
    containsHtml(string) {
        let doesContain = false;
        try {
            doesContain = /<\/?[a-z][\s\S]*>/i.test(string);
        } catch (e) {
            helpers.trackError(e);
        }
        return doesContain;
    },
    storeSave(key, value) {
        //console.log('storeSave', key, value)
        try {
            store.set(key, value);
        } catch (e) {
            helpers.trackError(e);
            alert(
                "There was an error saving to your Browser's local storage. Try clearing your cookies. If this error persists, email support@panelfox.io."
            );
        }
    },
    sortAlpha(array, key) {
        array.sort(function(a, b) {
            if (a[key] < b[key]) {
                return -1;
            }
            if (a[key] > b[key]) {
                return 1;
            }
            return 0;
        });
    },
    renderCurrencyAmount(currency, amount) {
        if (CURRENCY_POINTS == currency) {
            return `${amount} ${CURRENCY_SYMBOL[currency]}`;
        } else {
            return `${CURRENCY_SYMBOL[currency]}${amount}`;
        }
    },
    googleTagManagerEvent404() {
        console.log('googleTagManagerEvent404');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'error-404'
        });
    },
    googleTagManagerEventLogin(account) {
        const paidOrTrial = this.isAccountInTrial(account) ? 'trial' : 'paid';
        //console.log('googleTagManagerEventLogin', paidOrTrial);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'login-success',
            customer_type: paidOrTrial
        });
    },
    googleTagManagerEventSignup() {
        console.log('googleTagManagerEventSignup');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'trial-sign-up'
        });
    },
    renderLinksForUploadedScreenerFile(local_screener_answer) {
        try {
            let ret = [];
            //console.log('renderLinksForUploadedScreenerFile', local_screener_answer)
            if (this.isJsonString(local_screener_answer)) {
                local_screener_answer = JSON.parse(local_screener_answer);
                if (Array.isArray(local_screener_answer)) {
                    local_screener_answer.forEach((file, index) => {
                        ret.push(
                            <>
                                <a href={helpers.getUrlForUploadedScreenerFile(file)} target="_blank">
                                    View file {index + 1}
                                </a>
                                {local_screener_answer.length > index + 1 ? ', ' : ''}
                            </>
                        );
                    });
                }
            } else {
                ret.push(
                    <a href={helpers.getUrlForUploadedScreenerFile(local_screener_answer)} target="_blank">
                        View file
                    </a>
                );
            }
            return ret;
        } catch (e) {
            helpers.trackError(e);
        }
    },
    getUrlForUploadedScreenerFile(path) {
        return `${config.API_URL}/screener-get-uploaded-file?path=${path}`;
    },
    extractNumbers(str) {
        if (str) {
            let nums = '';
            const arr = str.match(/\d/g);
            if (arr && arr.length) {
                nums = arr.join('');
            }
            return nums;
        }
    },
    clickTableRowAddStyle(e) {
        const className = 'row-selected';
        const elements = document.getElementsByClassName(className);
        Array.prototype.forEach.call(elements, elem => {
            elem.classList.remove(className);
        });
        const elementType = e.target.tagName.toLowerCase();
        console.log(elementType);
        //if (elementType == 'td') {
        e.currentTarget.classList.add(className);
        //}
    },
    daysAgoOrDaysUntil(date) {
        try {
            var eventdate = moment(date);
            var todaysdate = moment();
            let diff = eventdate.diff(todaysdate, 'days');
            if (diff > 0) {
                return 'in ' + diff + ' days';
            } else {
                return Math.abs(diff) + ' days ago';
            }
        } catch (e) {
            console.log(e);
            return 'Error calculating date';
        }
    },
    daysUntilTrialExpires(account) {
        let days = false;
        const sub = this.getAccountSubscription(account);
        if (sub && sub.trial_ends_at) {
            const now = moment();
            const diff = moment.utc(sub.trial_ends_at).diff(now, 'days');
            if (diff >= 0) {
                days = diff + 1;
            }
        }
        return days;
    },
    isAccountInTrial(account) {
        let is_in_trial = false;
        try {
            if (account) {
                const sub = this.getAccountSubscription(account);
                if (sub && sub.name == 'trial') {
                    is_in_trial = true;
                }
            }
        } catch (e) {
            helpers.trackError(e);
        }

        return is_in_trial;
    },
    isTrialExpired(account) {
        let is_expired = false;
        try {
            if (account) {
                const sub = this.getAccountSubscription(account);
                if (sub && sub.trial_ends_at && moment.utc(sub.trial_ends_at).isBefore()) {
                    is_expired = true;
                }
            }
        } catch (e) {
            helpers.trackError(e);
        }

        return is_expired;
    },
    getAccountSubscription(account) {
        let sub = null;
        try {
            //console.log(account)
            if (account && account.subscriptions) {
                sub = account.subscriptions[0];
            }
        } catch (e) {
            helpers.trackError(e);
        }

        return sub;
    },
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    removeHtml(html) {
        return html.replace(/<[^>]*>?/gm, '');
    },
    getEmailStatusLabels(person) {
        let labels;
        if (person.email_status && person.email_status.length > 0) {
            person.email_status.forEach(es => {
                let explainer;
                let colorClass = '';
                switch (es.status) {
                    case 'format':
                        explainer = 'Bad e-mail format';
                        colorClass = 'warning';
                        break;
                    case 'bounce':
                        explainer = 'Hard bounce';
                        colorClass = 'error';
                        break;
                    case 'spam':
                        explainer = 'Spam complaint';
                        colorClass = 'error';
                }

                labels = <span className={`${colorClass}`}>{explainer}</span>;
            });
        }
        return labels;
    },
    renderDemoSignup() {
        return (
            <Link to="/demo">
                <Button type="submit" className="huge" mx={2}>
                    Watch Demo <FiPlayCircle />
                </Button>
            </Link>
        );
    },
    humanFileSize(bytes, si = true, dp = 1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + ' ' + units[u];
    },
    obscureEmail(email) {
        try {
            if (email) {
                const [name, domain] = email.split('@');
                let asterisks;
                if (name.length <= 2) {
                    asterisks = new Array(name.length + 1).join('*');
                } else {
                    asterisks = `${name[0]}${new Array(name.length).join('*')}`;
                }
                return `${asterisks}@${domain}`;
            }
        } catch (e) {
            helpers.trackError(e);
        }
    },
    add(a, b) {
        return a + b;
    },
    renderScreenerFooter(account) {
        if (account && account.screener_footer) {
            return <div className="account-email-footer color-text-secondary">{account.screener_footer}</div>;
        }
    },
    renderPoweredByPanelfox(account) {
        if (account && account.show_pfx_branding) {
            return (
                <Box textAlign="center" className="fs-body-14 color-text-secondary" my="40px">
                    Powered by{' '}
                    <img src="/logo.svg" alt="Panelfox Logo" style={{ height: '17px', margin: '0 0 1px 5px' }} />
                </Box>
            );
        } else {
            return <Box height="20px"></Box>;
        }
    },
    renderFavicon(account) {
        if (account && account.custom_domain && account.favicon) {
            //console.log('will render favicon ' + account.favicon)
            return <link rel="icon" type="image/x-icon" href={account.favicon} sizes="16x16" />;
        } else {
            return <link rel="icon" type="image/x-icon" href="/favicon-96x96.png" sizes="96x96" />;
        }
    },
    isValidRegex(expression) {
        var isValid = true;
        try {
            new RegExp(expression);
        } catch (e) {
            isValid = false;
        }
        return isValid;
    },
    isValidEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    },
    /**
     * Returns true if the account has the feature enabled
     *
     * @param {*} account The account object
     * @param {PAID_FEATURE} feature The feature to check
     * @returns {Boolean} true if the account has the feature enabled
     */
    hasFeatureEnabled(account, feature) {
        let enabled = false;
        try {
            enabled = account && account.features && account.features.includes(feature);
        } catch (e) {
            helpers.trackError(e);
        }
        return enabled;
    },
    hasVerifiedDomain(account) {
        return account.domains.some(domain => this.isDomainVerified(domain));
    },
    isDomainVerified(accountDomain) {
        return !!accountDomain.dkim_verified;
    },
    newFeatureTag(launched_at, style, text) {
        style = style || {};
        text = text || 'New';
        try {
            const now = moment();
            const days = now.diff(launched_at, 'days');

            if (days <= 14) {
                return (
                    <div className="new-feature-tag" style={style}>
                        {text}
                    </div>
                );
            }
        } catch (e) {
            helpers.trackError(e);
        }
    },
    hasTremendousIntegration(auth) {
        //console.log(this.props.auth.account);
        if (
            auth &&
            auth.account &&
            auth.account.tremendous_access_token &&
            auth.account.incentive_currency != CURRENCY_POINTS
        ) {
            return true;
        } else {
            return false;
        }
    },
    hasFirstPartyIncentivesEnabled(auth) {
        if (
            auth &&
            auth.account &&
            auth.account.tremendous_api_key &&
            auth.account.tremendous_campaign_id &&
            auth.account.incentive_currency != CURRENCY_POINTS &&
            this.hasFeatureEnabled(auth.account, PAID_FEATURE.FIRST_PARTY_INCENIVES)
        ) {
            return true;
        } else {
            return false;
        }
    },
    hasQualtricsIntegration(auth) {
        return (
            auth &&
            auth.account &&
            auth.account.qualtrics_api_token &&
            auth.account.qualtrics_organization_id &&
            auth.account.qualtrics_datacenter_id
        );
    },
    scrollToTop() {
        try {
            console.log('scrollToTop');
            // there was a bug.. it didnt scroll unless u add timeout
            setTimeout(() => window.scrollTo(0, 0), 0);
            // we now use CSS smooth scroll
            /*const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(helpers.scrollToTop);
                window.scrollTo(0, c - c / 8);
            }*/
        } catch (e) {
            helpers.trackError(e);
        }
    },
    getUserAvatar(user, w, h) {
        return user && user.avatar ? (
            <img src={user.avatar} style={{ width: w, height: h }} />
        ) : (
            <FiUser style={{ width: w, height: h }} />
        );
    },
    generateUID() {
        // I generate the UID from two parts here
        // to ensure the random number provide enough bits.
        var firstPart = (Math.random() * 46656) | 0;
        var secondPart = (Math.random() * 46656) | 0;
        firstPart = ('000' + firstPart.toString(36)).slice(-3);
        secondPart = ('000' + secondPart.toString(36)).slice(-3);
        return firstPart + secondPart;
    },
    isNumber(n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    },
    removeArrayElement(arr) {
        var what,
            a = arguments,
            L = a.length,
            ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    },
    findInString(needle, haystack) {
        return haystack.toLowerCase().includes(needle.toLowerCase());
    },
    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },
    betaFeature() {
        const cacheKey = 'enable_beta_feature';
        let enable_beta_feature = false;
        try {
            const queryParams = qs.parse(window.location.search, {
                ignoreQueryPrefix: true
            });

            if (queryParams.hasOwnProperty(cacheKey)) {
                console.log('queryParams[cacheKey]', queryParams[cacheKey]);
                if (queryParams[cacheKey] == true) {
                    store.set(cacheKey, 1);
                    enable_beta_feature = true;
                } else {
                    store.set(cacheKey, 0);
                    enable_beta_feature = false;
                }
            } else {
                enable_beta_feature = store.get(cacheKey);
            }

            console.log('enable_beta_feature', enable_beta_feature);
        } catch (e) {
            helpers.trackError(e);
        }
        return enable_beta_feature ? true : false;
    },
    checkImageUrl(url) {
        return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
    },

    convertTime12to24(time12h) {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM' || modifier === 'pm') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    },
    renderErrorLabel({ title, content, ...props }) {
        return (
            <NotificationCard
                {...props}
                type="danger"
                variant="dark"
                title={title}
                content={content}
                icon={<FiSlash />}
            />
        );
    },
    renderBlocklistLabel(person, options = {}) {
        if (person && person.custom_data_keys && person.custom_data_values2) {
            const on_blocklist = person.custom_data_values2[helpers.getPdkId(person.custom_data_keys, COL_BLOCKLIST)];
            return Number(on_blocklist) === 1 ? <BlockedBadge css={options.sx} /> : null;
        }
        return null;
    },
    personIsOptedOut(person) {
        if (
            this.personGetCustomValue(person, COL_OPT_IN) === '0' ||
            this.personGetCustomValue(person, COL_OPT_IN) === 0
        ) {
            return true;
        }
        return false;
    },
    personIsOptedOutSms(person) {
        if (
            this.personGetCustomValue(person, COL_OPT_IN_SMS) === '0' ||
            this.personGetCustomValue(person, COL_OPT_IN_SMS) === 0
        ) {
            return true;
        }
        return false;
    },
    personOnBlocklist(person) {
        if (this.personGetCustomValue(person, COL_BLOCKLIST) === '1') {
            return true;
        }
        return false;
    },
    stringToHtmlId(str) {
        return str.replace(/\s+/g, '-').toLowerCase();
    },
    makeBlocksFromText(text) {
        return {
            time: 1556098174501,
            blocks: [
                {
                    type: 'paragraph',
                    data: {
                        text
                    }
                }
            ]
        };
    },
    isObject(obj) {
        return obj !== undefined && obj !== null && obj.constructor == Object;
    },
    openTab(url) {
        window.open(url, '_blank');
    },
    popupWindow({ url, title, w, h }) {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        // eslint-disable-next-line
        const width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : window.screen.width;
        // eslint-disable-next-line
        const height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;
        const newWindow = window.open(
            url,
            title,
            `
          scrollbars=yes,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `
        );

        if (window.focus) newWindow.focus();
    },
    looseCompareStrings(str1, str2) {
        str1 = str1.toLowerCase();
        str2 = str2.toLowerCase();

        let re;
        // remove spaces, underscores, dashes
        [' ', '-', '_'].forEach(char => {
            re = new RegExp(char, 'g');

            str1 = str1.replace(re, '');
            str2 = str2.replace(re, '');
        });

        //console.log('looseCompareStrings', str1, str2);

        return str1 == str2;
    },
    isOnboardingTaskComplete(user, task_title) {
        let isComplete = false;
        if (user && user.onboarding) {
            const found = user.onboarding.find(ob => ob.task_title == task_title);
            if (found) {
                isComplete = true;
            }
        }
        // console.log('is task complete? ', isComplete);
        return isComplete;
    },
    getPersonInitials(p) {
        let initials = '';
        const firstName = this.personGetCustomValue(p, 'First Name');
        const lastName = this.personGetCustomValue(p, 'Last Name');
        if (firstName) {
            initials += firstName.split('')[0];
        }
        if (lastName) {
            initials += lastName.split('')[0];
        }
        return initials;
    },
    getUserInitials(u) {
        if (u && u.name) {
            const initials = u.name.split('')[0];
            return initials;
        }
    },
    getActiveAccountSubscriptions(account) {
        const subs = [];
        if (account.subscriptions && account.subscriptions.length > 0) {
            account.subscriptions.forEach(s => {
                if (s.stripe_status == 'active') {
                    subs.push(s);
                }
            });
        }
        return subs;
    },
    getAllAccountSubscriptions(account) {
        const subs = [];
        if (account.subscriptions && account.subscriptions.length > 0) {
            account.subscriptions.forEach(s => {
                subs.push(s);
            });
        }
        return subs;
    },
    allowSPReschedule(account, study_person) {
        return (
            account &&
            account.allow_session_changes == true &&
            study_person.spot &&
            moment.utc(study_person.spot).isAfter()
        );
    },
    getSchedulingLink_reschedule(study_person) {
        let link = '';
        // there should be a spot selected, but check if there is a gcal id or a calendly id
        if (study_person.calendly_invitee_uuid) {
            // https://api.calendly.com/event_types/
            link = `https://calendly.com/reschedulings/${study_person.calendly_invitee_uuid}`;
        } else if (study_person.uuid) {
            link = `${config.API_URL}/widget/session/${study_person.uuid}/reschedule`;
        }
        return link;
    },
    getSchedulingLink_cancel(study_person) {
        let link = '';
        // there should be a spot selected, but check if there is a gcal id or a calendly id
        if (study_person.calendly_invitee_uuid) {
            // https://api.calendly.com/event_types/
            link = `https://calendly.com/cancellations/${study_person.calendly_invitee_uuid}`;
        } else if (study_person.uuid) {
            link = `${config.API_URL}/widget/session/${study_person.uuid}/delete`;
        }
        return link;
    },
    async getSchedulingLink(study, study_person, action) {
        action = action || 'create';
        let link = '';

        // api.calendly.com/event_types/

        switch (action) {
            case 'cancel':
                link = this.getSchedulingLink_cancel(study_person);
                break;
            case 'reschedule':
                link = this.getSchedulingLink_reschedule(study_person);
                break;
            default:
            case 'create':
                if (study.scheduling_type == 'panelfox') {
                    link = `${config.API_URL}/widget/availability?preview=&token=${study.account.token}&study_id=${study.id}&study_person_id=${study_person.id}`;
                } else if (study.scheduling_type == 'calendly') {
                    const eventTypes = await services.getCalendlyEventTypes();
                    // console.log(eventTypes, study.calendly_event_type_uri)
                    const eventTypeMatched = eventTypes.find(et => et.uri === study.calendly_event_type_uri);
                    if (eventTypeMatched) {
                        link = eventTypeMatched.scheduling_url;
                        // pre-fill some data
                        const firstName = this.personGetCustomValue(study_person.person, 'First Name');
                        const lastName = this.personGetCustomValue(study_person.person, 'Last Name');
                        const email = this.personGetCustomValue(study_person.person, 'Email');
                        const fullName = `${firstName} ${lastName}`;
                        link += `?email=${email}&name=${fullName}`;
                        link += `&utm_campaign=studyId${study.id}`;
                        link += `&utm_source=accountId${study.account_id}`;
                    }
                }
                break;
        }

        return link;
    },
    moveInArray(arr, from, to) {
        console.log('moveInArray', arr, from, to);
        // Make sure a valid array is provided
        if (Object.prototype.toString.call(arr) !== '[object Array]') {
            throw new Error('Please provide a valid array');
        }

        // Delete the item from it's current position
        const item = arr.splice(from, 1);
        // console.log('moving this item', item)

        // Make sure there's an item to move
        if (!item.length) {
            throw new Error(`There is no item in the array at index ${from}`);
        }

        // Move the item to its new position
        arr.splice(to, 0, item[0]);
    },
    generateUUID() {
        let d = new Date().getTime(),
            d2 = (performance && performance.now && performance.now() * 1000) || 0;

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            let r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
        });
    },
    ID() {
        return `_${Math.random()
            .toString(36)
            .substr(2, 9)}`;
    },
    IDFromString(str) {
        if (typeof str === 'string') {
            return str
                .toLowerCase()
                .replace(/[^a-z0-9\s]/g, '')
                .trim()
                .replace(/\s+/g, '_');
        }
        return '-';
    },
    isKeyDownEnter(e) {
        if (e.key === 'Enter') {
            return true;
        }
        return false;
    },
    personGetFullName(person) {
        return `${this.personGetCustomValue(person, 'First Name')} ${this.personGetCustomValue(person, 'Last Name')}`;
    },
    personGetEmail(person) {
        return this.personGetCustomValue(person, 'Email');
    },
    personGetCustomDataKey(person, key) {
        const data_key_idx = person.custom_data_keys.findIndex(k => k.title == key);
        return data_key_idx >= 0 ? person.custom_data_keys[data_key_idx] : null;
    },
    personGetCustomValue(person, key) {
        let val = '';
        let key_id = null;

        if (person && person.custom_data_keys && person.custom_data_values2) {
            person.custom_data_keys.forEach(key_data => {
                if (key_data.title == key) {
                    key_id = key_data.id;
                }
            });

            if (key_id) {
                val = person.custom_data_values2[key_id];
            }
        }

        return val;
    },

    numberFormat(number, dec, dsep, tsep) {
        if (!number || Number.isNaN(number)) return 0;
        number = number.toFixed(dec || 0);
        const pindex = number.indexOf('.');
        let fnums;
        let decimals;
        const parts = [];
        if (pindex > -1) {
            fnums = number.substring(0, pindex).split('');
            decimals = (dsep || '.') + number.substr(pindex + 1);
        } else {
            fnums = number.split('');
            decimals = '';
        }
        do {
            parts.unshift(fnums.splice(-3, 3).join(''));
        } while (fnums.length);
        return parts.join(tsep || ',') + decimals;
    },

    isNeedleInHaystack(needle, haystack) {
        if (needle && haystack) {
            return haystack.toLowerCase().indexOf(needle.toLowerCase()) > -1;
        }
    },

    getAvailabilityLink(study) {
        const account_ui_domain = config.getAccountPrefixUrl(study.account);
        return `${account_ui_domain}/scheduling/schedule/?token=${authService.getAccountToken()}&study_uuid=${
            study.uuid
        }&is_preview=1`;
    },

    isValidUrl(str) {
        let url;
        try {
            url = new URL(str);
        } catch (_) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    },
    copyToClipboard(str) {
        navigator.clipboard.writeText(str);
    },
    getStudyPersonLink(studyPerson) {
        return `/studies/${studyPerson.study_id}/participants/${studyPerson.id}`;
    },
    getStudyPersonLocation(study_person, study) {
        let location = null;
        if (study_person && study) {
            if (study_person.calendly_invitee_uuid) {
                location = study_person.custom_location;
            } else if (study.location_type) {
                if (study.location_type === STUDY_LOCATION_TYPE.CUSTOM) {
                    // eslint-disable-next-line prefer-destructuring
                    location = study.location;
                } else if (study.location_type === STUDY_LOCATION_TYPE.ZOOM && study_person.zoom_meeting_link) {
                    location = study_person.zoom_meeting_link;
                } else if (study.location_type === STUDY_LOCATION_TYPE.MSTEAMS) {
                    // only show the location if the person is scheduled (MS Teams)
                    if (study_person.spot) {
                        location = study_person.custom_location;
                    }
                } else if (study.location_type === STUDY_LOCATION_TYPE.MEET && study_person.google_meet_link) {
                    // only show the location if the person is scheduled (MS Teams)
                    if (study_person.spot) {
                        location = study_person.google_meet_link;
                    }
                }
            } else {
                location = study_person.custom_location;
            }
        }

        return location;
    },
    doesUserHaveRouteAccess(authObject, min_role) {
        if (authObject) {
            const { account_role } = authObject;
            if (account_role.type == 'admin') {
                return true;
            }
            if (account_role.type == 'user') {
                if (min_role == 'admin') {
                    return false;
                }
                return true;
            }
            if (account_role.type == 'view') {
                if (min_role == 'admin' || min_role == 'user') {
                    return false;
                }
                return true;
            }
            if (account_role.type == 'limited') {
                if (min_role == 'admin' || min_role == 'user' || min_role == 'view') {
                    return false;
                }
                return true;
            }
        }
        return false;
    },
    getPdkId(all_pdk, column_title) {
        let found_id = null;
        if (all_pdk) {
            const found = all_pdk.find(pdk => pdk.title == column_title);
            if (found) {
                found_id = found.id;
            }
        }
        return found_id;
    },
    accountThemeButtonStyle(account, defaultStyle) {
        let style = defaultStyle || {};
        if (account) {
            if (account.branding && account.branding.primary) {
                style['backgroundColor'] = account.branding.primary;
            }
        }
        return style;
    },
    accountThemeLinkStyle(account, defaultStyle) {
        let style = defaultStyle || {};
        if (account) {
            if (account.branding && account.branding.primary) {
                style['color'] = account.branding.primary;
                style['border-color'] = account.branding.primary;
            }
        }
        return style;
    },
    getCheckboxValuesAsArray(element_name) {
        let checked_values = [];
        const checkboxes = document.getElementsByName(element_name);
        checkboxes.forEach(cb => {
            if (cb.checked) {
                checked_values.push(cb.value);
            }
        });
        return checked_values;
    },
    getEditorStateFromNotes(notes) {
        let editorState = null;
        let rawContentFromStore = null;
        if (notes) {
            if (helpers.isJsonString(notes)) {
                rawContentFromStore = convertFromRaw(JSON.parse(notes));
            } else {
                rawContentFromStore = convertFromHTML(notes);
            }

            editorState = EditorState.createWithContent(rawContentFromStore);
        } else {
            editorState = EditorState.createEmpty();
        }

        return editorState;
    },
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            h: hours,
            m: minutes,
            s: seconds
        };
        return obj;
    },
    getPanelistEligibilityText(person) {
        let panelistEligibilityText;
        let panelistEligibilityStatusArray = [];

        try {
            //console.log('person', person)
            if (person && person.panelist_eligibility2) {
                person.panelist_eligibility2.forEach(pe => {
                    if (pe.suppress == true) {
                        if (pe.type == 'email') {
                            panelistEligibilityStatusArray.push('email');
                        }
                        if (pe.type == 'schedule') {
                            panelistEligibilityStatusArray.push('scheduling');
                        }
                        if (pe.type == 'incentive') {
                            panelistEligibilityStatusArray.push('incentive');
                        }
                    }
                });

                //console.log('panelistEligibilityStatusArray', panelistEligibilityStatusArray)

                if (panelistEligibilityStatusArray.length) {
                    let firstName = this.personGetCustomValue(person, 'First Name');
                    panelistEligibilityText = (
                        <>Respondent is in {panelistEligibilityStatusArray.join(' and ')} fatigue</>
                    );
                }
            }
        } catch (e) {
            helpers.trackError(e);
        }

        return panelistEligibilityText;
    },
    renderPanelistEligibility(person) {
        let panelistEligibilityIssues;
        let text = this.getPanelistEligibilityText(person);

        if (text) {
            panelistEligibilityIssues = (
                <Flex className="notification-box-small bg-warning" mb={3} justifyContent={'space-between'}>
                    <Box>
                        <FiInfo className="va-top svg-sw-3px" style={{ margin: '4px 8px 0 0' }} />
                        {text}
                    </Box>
                </Flex>
            );
        }

        return panelistEligibilityIssues;
    },
    renderStudyEligibility(person, panelistEligibilityMore, studyFatigueExclude) {
        let panelistEligibilityIssues;
        try {
            let text = this.getPanelistEligibilityText(person);
            //console.log('text',text)

            if (text) {
                panelistEligibilityIssues = (
                    <Flex
                        className="notification-box-large bg-danger-light border-danger"
                        mb={3}
                        justifyContent={'space-between'}
                    >
                        <Box sx={{ flexShrink: 0 }}>
                            <FiAlertTriangle
                                className="va-top svg-sw-2px text-danger fs-16"
                                style={{ margin: '4px 12px 0 0' }}
                            />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Box>
                                {studyFatigueExclude ? (
                                    <Box className="black bold">Respondent fatigue manually circumvented</Box>
                                ) : (
                                    <Box className={'black bold'}>{text}</Box>
                                )}
                            </Box>
                            {panelistEligibilityMore}
                        </Box>
                    </Flex>
                );
            }
        } catch (e) {
            panelistEligibilityIssues = 'Error rendering component';
            helpers.trackError(e);
        }

        return panelistEligibilityIssues;
    },
    capitalize(s) {
        return (s && s[0].toUpperCase() + s.slice(1)) || '';
    },
    trackError(error, options = {}) {
        if (window.DD_RUM) window.DD_RUM.addError(error, options);
        helpers.logError(error, options);
    },
    logError(error, options = {}) {
        if (window.DD_LOGS) window.DD_LOGS.logger.error(error, options);

        // in local dev there's no DataDog so let's put the error in the console
        if (!window.DD_LOGS) console.error(error, options);
    },
    logInfo(message, options = {}) {
        if (window.DD_LOGS) window.DD_LOGS.logger.info(error, options);

        // in local dev there's no DataDog so let's put the error in the console
        if (!window.DD_LOGS) console.info(error, options);
    },
    isStringMappedToBoolean(s) {
        return [...FALSY_FORM_VALUES, ...TRUTHY_FORM_VALUES].includes(s);
    },
    extractFileExtension(filename) {
        return filename
            .split('.')
            .pop()
            .toLowerCase();
    },
    getDisplayValue(col, foundValue, timezone) {
        let displayValue;
        if (foundValue) {
            displayValue = foundValue;
            if (col.type == 'date' && displayValue) {
                displayValue = moment
                    .utc(displayValue)
                    .tz(timezone)
                    .fromNow();
            } else if (col.type == 'dropdown' && displayValue) {
                if (col && col.definition) {
                    const d_found = col.definition.find(d => d.value == displayValue);
                    if (d_found) {
                        displayValue = d_found.label;
                    }
                }
            } else if (col.type == 'checkbox' && displayValue) {
                if (this.isJsonString(displayValue) && !Array.isArray(displayValue)) {
                    displayValue = JSON.parse(displayValue);
                    // turn into array if not an array (there was some bug that saved data as object)
                }

                if (displayValue && !Array.isArray(displayValue)) {
                    try {
                        displayValue = Object.keys(displayValue).map(key => {
                            return displayValue[key];
                        });
                    } catch (e) {
                        this.trackError(e);
                    }
                }

                if (displayValue && Array.isArray(displayValue)) {
                    displayValue = displayValue.map((val, index) => {
                        let to_return = '';
                        try {
                            let d_index = col.definition.findIndex(cditem => cditem.value == val);
                            if (d_index > -1) {
                                to_return =
                                    col.definition[d_index].label + (index < displayValue.length - 1 ? ', ' : '');
                            }
                        } catch (e) {
                            this.trackError(e);
                            to_return = 'error';
                        }
                        return to_return;
                    });
                } else {
                    console.log('error displayValue', displayValue);
                    displayValue = 'error';
                }
            }
        }

        // boolean types variable should be represented as 'True' or 'False' instead of 1/0
        if (col.type === 'boolean' && typeof displayValue !== 'undefined') {
            const badgeText = Number(displayValue) > 0 ? 'True' : 'False';
            const type = Number(displayValue) > 0 ? 'success' : 'danger';

            displayValue = (
                <Badge type={type} css={{ width: 100 }}>
                    {badgeText}
                </Badge>
            );
        }

        return displayValue;
    },
    isEditableAttribute(title) {
        return ![COL_CREATED_AT, COL_UPDATED_AT].includes(title);
    },
    getFormTypeTitle(form_type_id) {
        let found = FORM_TYPES.find(ft => ft.id == form_type_id);
        if (found) {
            return found.title;
        } else {
            return 'Unknown form type';
        }
    },
    startsWithAny(arr, str) {
        return arr.some(item => str.startsWith(item));
    },
    /**
     * Returns the value of a query parameter from a URL
     *
     * @param {string} url The URL to extract the query parameter from
     * @param {string} key The key of the query parameter to extract
     */
    getQueryParam: (url, key) => {
        const query = url.split('?')[1];
        const params = new URLSearchParams(query);
        return params.get(key);
    },
    /**
     * Generate options for the minimum notice select input
     */
    getMinNoticeOptions() {
        const LABEL_SUFFIX = 'before the session';
        const HOUR = 60;
        const DAY = 24 * HOUR;
        return [
            {
                value: 0,
                label: 'Off'
            },
            {
                value: HOUR / 4,
                label: `15 minutes ${LABEL_SUFFIX}`
            },
            {
                value: HOUR / 2,
                label: `30 minutes ${LABEL_SUFFIX}`
            },
            {
                value: HOUR,
                label: `1 hour ${LABEL_SUFFIX}`
            },
            {
                value: HOUR * 2,
                label: `2 hours ${LABEL_SUFFIX}`
            },
            {
                value: HOUR * 3,
                label: `3 hours ${LABEL_SUFFIX}`
            },
            {
                value: HOUR * 4,
                label: `4 hours ${LABEL_SUFFIX}`
            },
            {
                value: HOUR * 5,
                label: `5 hours ${LABEL_SUFFIX}`
            },
            {
                value: HOUR * 6,
                label: `6 hours ${LABEL_SUFFIX}`
            },
            {
                value: HOUR * 12,
                label: `12 hours ${LABEL_SUFFIX}`
            },
            {
                value: DAY,
                label: `1 day ${LABEL_SUFFIX}`
            },
            {
                value: DAY * 2,
                label: `2 days ${LABEL_SUFFIX}`
            },
            {
                value: DAY * 3,
                label: `3 days ${LABEL_SUFFIX}`
            },
            {
                value: DAY * 4,
                label: `4 days ${LABEL_SUFFIX}`
            },
            {
                value: DAY * 5,
                label: `5 days ${LABEL_SUFFIX}`
            },
            {
                value: DAY * 6,
                label: `6 days ${LABEL_SUFFIX}`
            },
            {
                value: DAY * 7,
                label: `1 week ${LABEL_SUFFIX}`
            }
        ];
    },
    /**
     * Sort objects by their created_at property
     *
     * @param {Date} a The first object
     * @param {Date} b The second object
     */
    sortByCreatedAt: (a, b) => moment(b.createdAt) - moment(a.createdAt),
    /**
     * Validate a key against a lock. The key and lock are case-insensitive.
     *
     * @param {string} key
     * @param {string} lock
     * @returns {boolean} true if the key matches the lock
     */
    validateKey: (key, lock) => {
        return key.toUpperCase() === lock.toUpperCase();
    },
    /**
     * Check if a person data key PII status
     *
     * @param {Object} person person object
     * @param {string} key custom data key title
     * @returns {boolean} true if the key is PII
     */
    isPII: (person, key) => {
        const customDataKey = person.custom_data_keys.find(customDataKey => customDataKey.title === key);

        return !!customDataKey && customDataKey.pii_masked;
    },
    /*
     * Remove all blacklisted html tags and attributes
     *
     * @param {String} html
     */
    sanitizeHtmlTemplate: html => {
        const commonAttributes = ['style', 'class', 'id'];
        const commonTableAttributes = ['align', 'valign', 'bgcolor', 'width', 'height'];

        const whiteList = {
            // Common HTML5 tags and their attributes
            html: [...commonAttributes],
            body: [...commonAttributes, 'bgcolor'],
            head: [],
            title: [],
            meta: ['charset', 'name', 'content'],
            link: ['rel', 'href', 'type'],
            style: ['media', 'type'],
            a: [...commonAttributes, 'href', 'title', 'target', 'rel'],
            b: [...commonAttributes],
            center: [...commonAttributes],
            blockquote: [...commonAttributes, 'cite'],
            br: [...commonAttributes],
            hr: [...commonAttributes],
            button: [...commonAttributes, 'type', 'disabled'],
            col: [...commonAttributes, 'span', 'valign', 'bgcolor'],
            colgroup: [...commonAttributes, 'span', 'valign', 'bgcolor'],
            div: [...commonAttributes],
            em: [...commonAttributes],
            fieldset: [...commonAttributes, 'disabled', 'form', 'name'],
            h1: [...commonAttributes],
            h2: [...commonAttributes],
            h3: [...commonAttributes],
            h4: [...commonAttributes],
            h5: [...commonAttributes],
            h6: [...commonAttributes],
            header: [...commonAttributes],
            footer: [...commonAttributes],
            hgroup: [...commonAttributes],
            table: [...commonAttributes, ...commonTableAttributes, 'border', 'cellpadding', 'cellspacing'],
            tbody: [...commonAttributes, ...commonTableAttributes],
            thead: [...commonAttributes, ...commonTableAttributes],
            tfoot: [...commonAttributes, ...commonTableAttributes],
            tr: [...commonAttributes, ...commonTableAttributes],
            th: [...commonAttributes, ...commonTableAttributes, 'colspan', 'rowspan', 'headers', 'scope'],
            td: [...commonAttributes, ...commonTableAttributes, 'colspan', 'rowspan', 'headers'],
            i: [...commonAttributes],
            iframe: [...commonAttributes, 'src', 'width', 'height', 'frameborder', 'allow', 'allowfullscreen'],
            img: [...commonAttributes, 'src', 'alt', 'width', 'height'],
            label: [...commonAttributes, 'for'],
            li: [...commonAttributes],
            nav: [...commonAttributes],
            noscript: [],
            ol: [...commonAttributes, 'type', 'start', 'reversed'],
            option: [...commonAttributes, 'disabled', 'label', 'selected', 'value'],
            p: [...commonAttributes],
            pre: [...commonAttributes],
            small: [...commonAttributes],
            span: [...commonAttributes],
            strong: [...commonAttributes],
            sub: [...commonAttributes],
            summary: [...commonAttributes],
            sup: [...commonAttributes],
            u: [...commonAttributes],
            ul: [...commonAttributes]
        };

        const options = {
            whiteList: whiteList,
            css: false, // Do not remove any inline styles
            stripIgnoreTag: true, // Remove all tags not in the whitelist
            stripIgnoreTagBody: ['script'] // Remove the content inside script tags
        };

        return xss(html, options);
    }
};

export default helpers;
