import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import useQuery from 'hooks/useQuery';
import NiceSelect from 'components/NiceSelect';
import service_studies from 'services/studies';
import helpersStudy from 'utils/helpers-study';
import { QUOTA_WHEN_OPTION_LABELS, QUOTA_WHEN_OPTIONS } from 'utils/constants';

/**
 * Generate items for NiceSelect
 *
 * @param {Array<{ id: string, title: string }>} availability
 * @returns
 */
const getQuotaWhenOptions = availability => {
    return [
        {
            id: QUOTA_WHEN_OPTIONS.IS_SCHEDULED,
            title: QUOTA_WHEN_OPTION_LABELS.IS_SCHEDULED
        },
        {
            id: QUOTA_WHEN_OPTIONS.SCREENER_ANSWERED,
            title: QUOTA_WHEN_OPTION_LABELS.SCREENER_ANSWERED
        },
        ...availability
    ];
};

/**
 * Generate mapValueToLabel for NiceSelect
 *
 * @param {Array<{ id: string, title: string }>} availability
 * @returns
 */
const getQuotaWhenOptionsMap = availability => {
    return availability.reduce(
        (map, item) => {
            if (!item.label) {
                map[item.id] = item.title;
            }
            return map;
        },
        {
            [QUOTA_WHEN_OPTIONS.IS_SCHEDULED]: QUOTA_WHEN_OPTION_LABELS.IS_SCHEDULED,
            [QUOTA_WHEN_OPTIONS.SCREENER_ANSWERED]: QUOTA_WHEN_OPTION_LABELS.SCREENER_ANSWERED
        }
    );
};

/**
 * Display a dropdown with Quota When options.
 *
 * @param {Object} props
 * @param {Object} props.study Study object
 * @param {string} props.defaultValue Default value
 * @param {function} props.onChange The function to handle change event
 **/
function QuotaWhenSelect({ study, defaultValue, onChange }) {
    const { addToast } = useToasts();
    const [availabilityArray, setAvailabilityArray] = useState([]);
    const { data } = useQuery({
        queryFn: ({ studyId, showTakenSpots }) => service_studies.getStudyAvailability(studyId, showTakenSpots),
        variables: {
            studyId: study.id,
            showTakenSpots: 1
        },
        onSuccess: data => {
            const availabilityArray = helpersStudy.generateStudyAvailabilityArray(data, study.timezone, study.timezone);
            setAvailabilityArray(availabilityArray);
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    return (
        <NiceSelect
            variant="transparent"
            defaultLabel=""
            defaultValue={defaultValue}
            positionLeft="0px"
            items={getQuotaWhenOptions(availabilityArray)}
            mapValueToLabel={getQuotaWhenOptionsMap(availabilityArray)}
            onChange={onChange}
        />
    );
}

export default QuotaWhenSelect;
