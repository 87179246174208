import React, { useState } from 'react';
import { Box, Button, Flex } from 'rebass';
import ReactQuill, { Quill } from 'react-quill';
import classNames from 'classnames';
import { FiEdit } from 'react-icons/fi';

const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

const NULL_HTML = '<div><br></div>';

/**
 * SaveEditEditor component.
 *
 * @param {string | undefined | null} defaultValue The default value of the editor.
 * @param {Function} onSave The save function.
 * @param {Quill.QuillOptions['modules']} modules
 * @returns {JSX.Element} The rendered component.
 */
export function SaveEditEditor({ defaultValue, onSave, modules }) {
    const [html, setHtml] = useState(defaultValue);
    const [shadowHtml, setShadowHtml] = useState(null); // Used to store the original value when editing.
    const [isEditing, setIsEditing] = useState(false);

    const onRevert = () => {
        setHtml(shadowHtml);
        setShadowHtml(null);
        setIsEditing(false);
    };

    const onInternalSave = () => {
        const content = html === NULL_HTML ? null : html;
        onSave(content);
        setShadowHtml(null);
        setIsEditing(false);
    };

    const onEdit = () => {
        setIsEditing(true);
        setShadowHtml(html);
    };

    return (
        <Box>
            <ReactQuill
                theme="snow"
                className={classNames('quill-render', { 'quill-render-hidden-toolbar': !isEditing })}
                readOnly={!isEditing}
                modules={modules}
                value={html}
                onChange={html => setHtml(html)}
            />
            <Flex mt="6px" style={{ gap: '8px' }}>
                {isEditing ? (
                    <>
                        <Button variant="secondary-gray" onClick={onRevert}>
                            Cancel
                        </Button>
                        <Button onClick={onInternalSave}>Save</Button>
                    </>
                ) : (
                    <Button variant="secondary-gray" onClick={onEdit}>
                        <FiEdit /> Edit
                    </Button>
                )}
            </Flex>
        </Box>
    );
}

export default SaveEditEditor;
