import React, { useState } from 'react';
import { Box, Button } from 'rebass';
import { Input, Label, Switch } from '@rebass/forms';
import { useToasts } from 'react-toast-notifications';

import useMutation from 'hooks/useMutation';
import service_people from 'services/people';

export default function CreatePanel(props) {
    const { addToast } = useToasts();
    const [title, setTitle] = useState('');
    const [rbacRestricted, setRbacRestricted] = useState(0);

    const { mutate: createPanel, isLoading: isPanelCreating } = useMutation({
        mutationFn: async () => {
            return await service_people.createPanel(title, rbacRestricted);
        },
        onSuccess: panel => {
            props.onClose();
            props.onCreate(panel);
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    function onSubmit(e) {
        e.preventDefault();
        createPanel();
    }

    return (
        <form onSubmit={onSubmit}>
            <Box>
                <Label>Panel title</Label>
                <Input
                    type="text"
                    name="create_panel_title"
                    placeholder="New panel"
                    autoComplete="off"
                    required
                    autoFocus
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                />
            </Box>
            <Label mt={3}>Permissions</Label>
            <Box>
                <Switch
                    className="switch-small"
                    name="rbac_restricted"
                    checked={rbacRestricted ? true : false}
                    onClick={() => setRbacRestricted(rbacRestricted ? 0 : 1)}
                />
                <span className="text-primary switch-label">Only allow certain users to access this panel</span>
            </Box>
            {rbacRestricted == true && (
                <Box>
                    <small>You'll be able to manage permissions in Panel Settings.</small>
                </Box>
            )}
            <Box className="modal-actions">
                <Button variant="secondary-gray" type="button" mr={3} onClick={props.onClose}>
                    Cancel
                </Button>
                <Button type="submit" variant="primary" mr={0} className="modal-primary" disabled={isPanelCreating}>
                    Create panel
                </Button>
            </Box>
        </form>
    );
}
