import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Button, Flex } from 'rebass';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import integrations from 'services/integrations';
import auth from 'services/auth';
import H1 from 'components/H1';
import H2 from 'components/H2';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';

ZoomSetupPage.propTypes = {
    auth: PropTypes.object,
    location: PropTypes.object
};

function ZoomSetupPage(props) {
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        // The "code" param indicates we've been redirected to this page
        // after successful Zoom auth. Now, we get the access token and
        // store it with our user.
        const query = new URLSearchParams(props.location.search);
        const code = query.get('code');

        async function createZoomToken() {
            if (code && !props.auth.user.zoom_account_id) {
                await integrations.createZoomToken(code);
                auth.checkAuth();
            }
            setLoading(false);
        }

        if (code) {
            createZoomToken();
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <AppPageWrapper>
            <Helmet>
                <title>Zoom Integration</title>
            </Helmet>

            <AppPageWrapperSectionHeader>
                <H1>Zoom integration was successful!</H1>
            </AppPageWrapperSectionHeader>
            <AppPageWrapperSectionBody>
                {loading ? (
                    <div style={{ width: '100%' }}>
                        <LoadingWrapper>
                            <LoadingIndicator />
                        </LoadingWrapper>
                    </div>
                ) : (
                    <Box m={4}>
                        <H2>How to use it:</H2>
                        <img alt="Set meeting location to Zoom" src="location-menu.png"></img>
                        <Box my={3}>
                            1. On the Study &gt; Scheduling page, click on the <strong>Add a location</strong> field.
                            <br />
                            2. Select <strong>Zoom</strong>, and choose who will host the new Zoom meetings.
                        </Box>
                        <Flex>
                            <Button onClick={() => history.push('/studies')}>Go to Studies</Button>
                            <Button onClick={() => history.push('/me')} variant="secondary">
                                Manage Integration
                            </Button>
                        </Flex>
                    </Box>
                )}
            </AppPageWrapperSectionBody>
        </AppPageWrapper>
    );
}

export default ZoomSetupPage;
